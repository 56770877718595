import React,{Fragment,useContext, useEffect,useState} from 'react';
import {Link} from 'react-router-dom';
import loading_img from "../../../images/profile/loading.gif";
import { Router } from "react-router-dom";
import { GlobalSet } from '../global/GlobalProvider';
//Import Components
import axios from "axios";
import { Row, Col, Card, Carousel,Accordion } from 'react-bootstrap'
import Slider from "react-slick";
import swal from "sweetalert";


const Home = () => {
   const { BaseUrl } = GlobalSet();
   const [homeitem, setHomeItem] = useState([]);  
    useEffect(()=>{
        const data = {
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
        }
         axios.post('/casino-providers',data)
         .then(response=>{
           setHomeItem(response.data.casino_providers)
           
           })
           .catch(error=>{
             console.log(error)
         })


    },[])


  const [isCollapsed, setCollapsed] = useState(false);
  const handleToggleCollapse = () => {
    setCollapsed(!isCollapsed);
  };

return(
<>
   <span style={{  marginTop: '-36px' }} className="d-xl-block">
      <div className="row">
      <Fragment>
          <Col xl="12" className="p-1" >
              <Card.Body className="p-2" >
              <div className="form-inline">
                <div className="row">


                </div>
              </div>
              </Card.Body>
          </Col>
      </Fragment>

      </div>
    </span>
    </>
  )
}
export default Home;